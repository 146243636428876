<template>
  <div class="wrap">
    <i
      @click.stop="searchInputFn"
      class="serach-icon iconfont icon-sousuo"
      :style="{ color: !!seacrchConfig.searchVal ? '#20a0ff' : '' }"
    ></i>
    <input
      type="input"
      @keyup.enter="customSearchEvent('search')"
      v-model="seacrchConfig.searchVal"
      class="xa-search-weui__input"
      :style="{ width: width }"
      :placeholder="placeholder"
    />
  </div>
</template>
<script>
// emit('search-bar',{action:input/search,val:val})
export default {
  data() {
    return {
      isShow: '',
      seacrchConfig: {
        isShow: false,
        isFocusing: false,
        searchVal: ''
      }
    }
  },
  props: {
    width: {},
    placeholder: {
      type: String,
      default: '输入搜索内容'
    }
  },
  watch: {
    'seacrchConfig.searchVal'(val) {
      this.$emit('search-bar', {
        action: 'input',
        val: val
      })
    }
  },
  methods: {
    customSearchEvent() {
      this.$emit('search-bar', {
        action: 'search',
        val: this.seacrchConfig.searchVal
      })
    },
    /** 搜索 点击 "取消" */
    searchCancelFn() {
      this.seacrchConfig.isFocusing = false
      this.searchVal = ''
    },
    searchInputFn() {
      // 点击搜索
      this.$emit('search-bar', {
        action: 'search',
        val: this.seacrchConfig.searchVal
      })
    }
  }
}
</script>
<style scoped>
.wrap {
  position: relative;
}

.serach-icon {
  position: absolute;
  right: 11px;
  top: 2px;
  line-height: 26px;
  font-size: 18px;
  cursor: pointer;
  color: transparent;
  transition: all 0.5s ease-in-out;
}

.xa-search-weui__input {
  width: 100%;
  height: 30px;
  line-height: 30px !important;
  font-size: 12px;
  border-radius: 15px;
  padding: 0 35px 0 17px;
  border: 1px solid rgb(191, 203, 217);
  background: 0 0;
  box-sizing: border-box;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.xa-search-weui__input:hover {
  border: 1px solid #20a0ff;
}

.xa-search-weui__input:focus {
  outline: 0 !important;
}
</style>
